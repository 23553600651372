@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500,600,700|Montserrat|Open+Sans|Roboto&display=swap);
@import url(https://code.highcharts.com/css/stocktools/gui.css);
@import url(https://code.highcharts.com/css/annotations/popup.css);
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-tab-list {
  flex-wrap: wrap;
}

.bp3-label {
  min-width: 25%;
}

.bp3-slider {
  min-width: 200px !important;
}

.bp3-dark svg g g text {
  fill: #f5f8fa !important;
}

.hidden {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
}

@media only screen and (max-width: 768px) {
  .bp3-card {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .bp3-form-group.bp3-inline label.bp3-label {
    min-width: 28%;
  }
}
body > div.bp3-portal > div > div > div > div.bp3-popover-content > div > div > input {
  width: 100% !important;
}
.styles_oifToaster__1Af3K {
  z-index: 15;
}
.styles_bp3-tab-list__iXLb6 {
  flex-wrap: wrap;
}

.styles_bp3-label__2qU62 {
  min-width: 25%;
}

.styles_bp3-slider__3cU_Q {
  min-width: 200px !important;
}

.styles_bp3-dark__3FHPJ svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__3sxsP {
  display: none;
}

.styles_logo__3FgwY {
  align-items: center;
  position: relative;
  text-decoration: none !important;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -1px;
  cursor: pointer;
}

.styles_logo__3FgwY span {
  display: inline-block;
  font-weight: 400;
}

.styles_darkModeBtn__EvbOH {
  float: right;
  outline: 0;
  margin-left: auto;
  display: flex;
}

.styles_logo__3FgwY {
  display: flex;
}

@media only screen and (min-width: 768px) {
  .styles_logo__3FgwY {
    display: flex;
  }
  .styles_navbarHeading__z-GY1 {
    margin-right: 15px;
  }
}
.styles_navbarHeading__z-GY1 {
  margin-right: 0px;
}

.styles_menuItemPadding__2LChG {
  margin-left: 5px;
}
@media (max-width: 700px) {
  .styles_menuItemPadding__2LChG {
    margin-left: 0px;
  }
}

.styles_blogButton__MDFBm span {
  color: inherit !important;
}

@media (max-width: 700px) {
  .styles_navBar__3yY3R {
    height: 60px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .styles_navBar__3yY3R .bp3-button {
    flex-direction: column;
    align-self: center;
  }
  .styles_navBar__3yY3R .bp3-navbar-group {
    height: 100%;
  }
  .styles_navBar__3yY3R .styles_blogButton__MDFBm {
    justify-content: start;
  }
}
.styles_footer__3iKZQ {
  margin-top: 1rem;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
}

.styles_horizontalFlex__3TRpT {
  display: flex;
  flex-direction: row;
  /* align-content: flex-end; */
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
}

.styles_dialogBody__3T9fE {
  text-align: justify;
}

.styles_disclaimerDialog__3gETQ {
  width: 50%;
}
@media (max-width: 700px) {
  .styles_disclaimerDialog__3gETQ {
    width: 90%;
  }
}

.styles_disclaimerText__1Eo4c {
  text-align: center;
}
@-webkit-keyframes styles_pulse__3zLPn {
  0% {
    -webkit-box-shadow: 0 0 4px rgba(14, 90, 138, 0.2);
  }
  50% {
    -webkit-box-shadow: 0 0 4px rgba(14, 90, 138, 0.99);
  }
  100% {
    -webkit-box-shadow: 0 0 4px rgba(14, 90, 138, 0.2);
  }
}
@keyframes styles_pulse__3zLPn {
  0% {
    box-shadow: 0 0 4px rgba(14, 90, 138, 0.2);
  }
  50% {
    box-shadow: 0 0 4px rgba(14, 90, 138, 0.99);
  }
  100% {
    box-shadow: 0 0 4px rgba(14, 90, 138, 0.2);
  }
}
.pickFundBtn {
  -webkit-animation: styles_pulse__3zLPn 2s infinite ease-in-out;
          animation: styles_pulse__3zLPn 2s infinite ease-in-out;
}
.styles_bp3-tab-list__6YAcs {
  flex-wrap: wrap;
}

.styles_bp3-label__1o09Z {
  min-width: 25%;
}

.styles_bp3-slider__1_v13 {
  min-width: 200px !important;
}

.styles_bp3-dark__1bRA_ svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__39VSS {
  display: none;
}

.styles_secondaryMenu__2fDE- {
  position: absolute;
  width: 90%;
  margin-top: 25px;
  background-color: white;
  margin-left: 5%;
}
@media (max-width: 1920px) {
  .styles_secondaryMenu__2fDE- {
    padding: 15px;
    margin-top: 30px;
  }
}
@media (max-width: 700px) {
  .styles_secondaryMenu__2fDE- {
    padding: 12px;
    width: 96%;
    margin-left: 2%;
  }
  .styles_secondaryMenu__2fDE- .bp3-button-group {
    flex-direction: column;
  }
  .styles_secondaryMenu__2fDE- .fundsSelector {
    align-self: center;
  }
  .styles_secondaryMenu__2fDE- .bp3-tab-list {
    justify-content: center;
  }
}

.styles_menuDivider__2aSKF {
  margin-right: 15px;
}
.loaderDarkTheme .loader__bar, .loaderDarkTheme .loader__ball {
  background-color: #f5f8fa;
}

.loader {
  margin: auto;
  width: 50%;
  top: 35%;
  padding-top: 15px;
  position: relative;
  width: 75px;
  height: 100px;
}
.loader__bar {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 50%;
  background: #031a61;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.loader__bar:nth-child(1) {
  left: 0px;
  -webkit-transform: scale(1, 0.2);
          transform: scale(1, 0.2);
  -webkit-animation: barUp1 4s infinite;
          animation: barUp1 4s infinite;
}
.loader__bar:nth-child(2) {
  left: 15px;
  -webkit-transform: scale(1, 0.4);
          transform: scale(1, 0.4);
  -webkit-animation: barUp2 4s infinite;
          animation: barUp2 4s infinite;
}
.loader__bar:nth-child(3) {
  left: 30px;
  -webkit-transform: scale(1, 0.6);
          transform: scale(1, 0.6);
  -webkit-animation: barUp3 4s infinite;
          animation: barUp3 4s infinite;
}
.loader__bar:nth-child(4) {
  left: 45px;
  -webkit-transform: scale(1, 0.8);
          transform: scale(1, 0.8);
  -webkit-animation: barUp4 4s infinite;
          animation: barUp4 4s infinite;
}
.loader__bar:nth-child(5) {
  left: 60px;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-animation: barUp5 4s infinite;
          animation: barUp5 4s infinite;
}
.loader__ball {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #031a61;
  border-radius: 50%;
  -webkit-animation: ball 4s infinite;
          animation: ball 4s infinite;
}

@-webkit-keyframes ball {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  5% {
    -webkit-transform: translate(8px, -14px);
            transform: translate(8px, -14px);
  }
  10% {
    -webkit-transform: translate(15px, -10px);
            transform: translate(15px, -10px);
  }
  17% {
    -webkit-transform: translate(23px, -24px);
            transform: translate(23px, -24px);
  }
  20% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  27% {
    -webkit-transform: translate(38px, -34px);
            transform: translate(38px, -34px);
  }
  30% {
    -webkit-transform: translate(45px, -30px);
            transform: translate(45px, -30px);
  }
  37% {
    -webkit-transform: translate(53px, -44px);
            transform: translate(53px, -44px);
  }
  40% {
    -webkit-transform: translate(60px, -40px);
            transform: translate(60px, -40px);
  }
  50% {
    -webkit-transform: translate(60px, 0);
            transform: translate(60px, 0);
  }
  57% {
    -webkit-transform: translate(53px, -14px);
            transform: translate(53px, -14px);
  }
  60% {
    -webkit-transform: translate(45px, -10px);
            transform: translate(45px, -10px);
  }
  67% {
    -webkit-transform: translate(37px, -24px);
            transform: translate(37px, -24px);
  }
  70% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  77% {
    -webkit-transform: translate(22px, -34px);
            transform: translate(22px, -34px);
  }
  80% {
    -webkit-transform: translate(15px, -30px);
            transform: translate(15px, -30px);
  }
  87% {
    -webkit-transform: translate(7px, -44px);
            transform: translate(7px, -44px);
  }
  90% {
    -webkit-transform: translate(0, -40px);
            transform: translate(0, -40px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes ball {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  5% {
    -webkit-transform: translate(8px, -14px);
            transform: translate(8px, -14px);
  }
  10% {
    -webkit-transform: translate(15px, -10px);
            transform: translate(15px, -10px);
  }
  17% {
    -webkit-transform: translate(23px, -24px);
            transform: translate(23px, -24px);
  }
  20% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  27% {
    -webkit-transform: translate(38px, -34px);
            transform: translate(38px, -34px);
  }
  30% {
    -webkit-transform: translate(45px, -30px);
            transform: translate(45px, -30px);
  }
  37% {
    -webkit-transform: translate(53px, -44px);
            transform: translate(53px, -44px);
  }
  40% {
    -webkit-transform: translate(60px, -40px);
            transform: translate(60px, -40px);
  }
  50% {
    -webkit-transform: translate(60px, 0);
            transform: translate(60px, 0);
  }
  57% {
    -webkit-transform: translate(53px, -14px);
            transform: translate(53px, -14px);
  }
  60% {
    -webkit-transform: translate(45px, -10px);
            transform: translate(45px, -10px);
  }
  67% {
    -webkit-transform: translate(37px, -24px);
            transform: translate(37px, -24px);
  }
  70% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  77% {
    -webkit-transform: translate(22px, -34px);
            transform: translate(22px, -34px);
  }
  80% {
    -webkit-transform: translate(15px, -30px);
            transform: translate(15px, -30px);
  }
  87% {
    -webkit-transform: translate(7px, -44px);
            transform: translate(7px, -44px);
  }
  90% {
    -webkit-transform: translate(0, -40px);
            transform: translate(0, -40px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@-webkit-keyframes barUp1 {
  0% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  40% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  90% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
}
@keyframes barUp1 {
  0% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  40% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  90% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
}
@-webkit-keyframes barUp2 {
  0% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  40% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  50% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  90% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  100% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
}
@keyframes barUp2 {
  0% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  40% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  50% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  90% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  100% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
}
@-webkit-keyframes barUp3 {
  0% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
  100% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
}
@keyframes barUp3 {
  0% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
  100% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
}
@-webkit-keyframes barUp4 {
  0% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  40% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  50% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  90% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  100% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
}
@keyframes barUp4 {
  0% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  40% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  50% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  90% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  100% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
}
@-webkit-keyframes barUp5 {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  40% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  90% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes barUp5 {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  40% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  90% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
.styles_dot__1IngF {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 5px;
}

.styles_customLegend__1fL6x {
  margin-top: 50px;
}

.styles_customLegendItem__FFRu1 {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.styles_disableSelection__nXnd8 {
  -webkit-user-select: none;
  user-select: none;
}
.styles_bp3-tab-list__3LWYs {
  flex-wrap: wrap;
}

.styles_bp3-label__jgmD4 {
  min-width: 25%;
}

.styles_bp3-slider__3YJyM {
  min-width: 200px !important;
}

.styles_bp3-dark__oA2KU svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__13JZo {
  display: none;
}

body {
  background-color: #f5f8fa;
}

.styles_darkBackgroundColor__J_f0K {
  background-color: #30404d;
}

.styles_topCard__3s10G {
  margin-top: 2vh;
  padding: 0rem 2rem 1rem 2rem;
}
@media (max-width: 1920px) {
  .styles_topCard__3s10G {
    padding: 0rem 0 0.5rem 0;
  }
}

@media (max-width: 700px) {
  .styles_analisysContainer__1gWGc {
    padding-top: 72px;
  }
}

.styles_panelDivider__2Eql8 {
  margin-right: 20px !important;
}

.styles_bubu__3gAtu {
  position: absolute;
  width: 90%;
  margin-top: 25px;
  background-color: white;
  margin-left: 5%;
}

@media (max-width: 1920px) {
  .styles_fundsOverviewCard__1JWY3 {
    overflow: auto;
  }
}


.styles_bp3-tab-list__1R-If {
  flex-wrap: wrap;
}

.styles_bp3-label__1oVtF {
  min-width: 25%;
}

.styles_bp3-slider__1foEa {
  min-width: 200px !important;
}

.styles_bp3-dark__Oil7F svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__1cwbd {
  display: none;
}

.styles_mainCard__15Dow {
  padding: 15px 15px 15px 15px;
  text-align: center;
}

.styles_lg1_5__2ASzG {
  flex-basis: 11.666667%;
  max-width: 11.666667%;
}
@media (max-width: 1920px) {
  .styles_lg1_5__2ASzG {
    flex-basis: 13.5%;
    max-width: 13.5%;
  }
}
@media (max-width: 700px) {
  .styles_lg1_5__2ASzG {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1920px) {
  .styles_trailingCard__3-qV6 {
    padding: 10px;
  }
}

h1 {
  margin: 0;
}

.styles_priceFont__2yPFR {
  font-size: 1.5rem;
}
@media (max-width: 1920px) {
  .styles_priceFont__2yPFR {
    font-size: 1rem;
  }
}

.styles_periodFont__10z2B {
  font-weight: 500;
}

@media (max-width: 700px) {
  .styles_trailingReturnsRow__Rf-Vs {
    flex-direction: column;
  }
}
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.annual-return-table.table-light tbody tr td {
  border-bottom: 1px solid #dbdcdd;
}

.annual-return-table.table-dark tbody tr td {
  border-bottom: 1px solid #4F5D67;
}

.annual-return-table {
  width: 100%;
  padding-top: 5px;
}
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.summary-statistics-table.table-light tbody tr td {
  border-bottom: 1px solid #dbdcdd;
}

.summary-statistics-table.table-dark tbody tr td {
  border-bottom: 1px solid #4f5d67;
}

.summary-statistics-table {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 15px;
}

@media (max-width: 700px) {
  .summary-statistics-table tbody td {
    padding-left: 2px;
  }
}
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.risk-class {
  float: right;
}

.risk-class-box {
  float: right;
  flex: auto;
}

.risk-class span {
  width: 1rem;
  display: inline-block;
  height: 10px;
  border: 2px solid #182026;
  margin: 0 2px;
}

.risk-class span:nth-child(1) {
  height: 10px;
}

.risk-class span:nth-child(2) {
  height: 15px;
}

.risk-class span:nth-child(3) {
  height: 20px;
}

.risk-class span:nth-child(4) {
  height: 25px;
}

.risk-class span:nth-child(5) {
  height: 30px;
}

.risk-class span:nth-child(6) {
  height: 35px;
}

.risk-class span:nth-child(7) {
  height: 40px;
}

.risk-class-1 span:nth-child(1) {
  background: #182026;
}

.risk-class-2 span:nth-child(-n+2) {
  background: #182026;
}

.risk-class-3 span:nth-child(-n+3) {
  background: #182026;
}

.risk-class-4 span:nth-child(-n+4) {
  background: #182026;
}

.risk-class-5 span:nth-child(-n+5) {
  background: #182026;
}

.risk-class-6 span:nth-child(-n+6) {
  background: #182026;
}

.risk-class-7 span:nth-child(-n+7) {
  background: #182026;
}

.risk-class-1.dark span:nth-child(1) {
  background: #a7b6c2;
}

.risk-class-2.dark span:nth-child(-n+2) {
  background: #a7b6c2;
}

.risk-class-3.dark span:nth-child(-n+3) {
  background: #a7b6c2;
}

.risk-class-4.dark span:nth-child(-n+4) {
  background: #a7b6c2;
}

.risk-class-5.dark span:nth-child(-n+5) {
  background: #a7b6c2;
}

.risk-class-6.dark span:nth-child(-n+6) {
  background: #a7b6c2;
}

.risk-class-7.dark span:nth-child(-n+7) {
  background: #a7b6c2;
}

.risk-class.dark span {
  border: 2px solid #a7b6c2;
}
.styles_bp3-tab-list__2Rhc- {
  flex-wrap: wrap;
}

.styles_bp3-label__-YU2m {
  min-width: 25%;
}

.styles_bp3-slider__1bnVd {
  min-width: 200px !important;
}

.styles_bp3-dark__1eB1J svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__zNLF7 {
  display: none;
}

.styles_mainCard__3_nh2 {
  text-align: center;
}

.styles_secondRow__3QlWr {
  margin-top: 20px;
}

.styles_lg1_5__KIM4x {
  flex-basis: 11.666667%;
  max-width: 11.666667%;
}

h1 {
  margin: 0;
}

.styles_riskClassContainer__ZH17Q {
  width: 21vw;
  min-width: 260px;
  float: right;
}
@media (max-width: 700px) {
  .styles_riskClassContainer__ZH17Q {
    width: auto;
  }
}

.styles_hypotheticalGrowthChartContainer__1GVgt {
  height: 600px;
}
@media (max-width: 700px) {
  .styles_hypotheticalGrowthChartContainer__1GVgt {
    margin-top: 20px;
    height: 400px;
  }
}
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.summary-statistics-table.table-light tbody tr td {
  border-bottom: 1px solid #dbdcdd;
}

.summary-statistics-table.table-dark tbody tr td {
  border-bottom: 1px solid #4F5D67;
}

.summary-statistics-table {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 15px;
}
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.annual-return-table.table-light tbody tr td {
  border-bottom: 1px solid #dbdcdd;
}

.annual-return-table.table-dark tbody tr td {
  border-bottom: 1px solid #4F5D67;
}

.annual-return-table {
  width: 100%;
  padding-top: 5px;
}
.styles_monteCarloChart__hXqOA {
  width: 100%;
  height: 95%;
}
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.annual-return-table.table-light tbody tr td {
  border-bottom: 1px solid #dbdcdd;
}

.annual-return-table.table-dark tbody tr td {
  border-bottom: 1px solid #4F5D67;
}

.annual-return-table {
  width: 100%;
  padding-top: 5px;
}
.uplot, .uplot *, .uplot *::before, .uplot *::after {box-sizing: border-box;}.uplot {font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";line-height: 1.5;width: -webkit-max-content;width: max-content;}.u-title {text-align: center;font-size: 18px;font-weight: bold;}.u-wrap {position: relative;-webkit-user-select: none;user-select: none;}.u-over, .u-under {position: absolute;}.u-under {overflow: hidden;}.uplot canvas {display: block;position: relative;width: 100%;height: 100%;}.u-legend {font-size: 14px;margin: auto;text-align: center;}.u-inline {display: block;}.u-inline * {display: inline-block;}.u-inline tr {margin-right: 16px;}.u-legend th {font-weight: 600;}.u-legend th > * {vertical-align: middle;display: inline-block;}.u-legend .u-marker {width: 1em;height: 1em;margin-right: 4px;border: 2px solid transparent;}.u-inline.u-live th::after {content: ":";vertical-align: middle;}.u-inline:not(.u-live) .u-value {display: none;}.u-series > * {padding: 4px;}.u-series th {cursor: pointer;}.u-legend .u-off > * {opacity: 0.3;}.u-select {background: rgba(0,0,0,0.07);position: absolute;pointer-events: none;}.u-cursor-x, .u-cursor-y {position: absolute;left: 0;top: 0;pointer-events: none;will-change: transform;z-index: 100;}.u-cursor-x {height: 100%;border-right: 1px dashed #607D8B;}.u-cursor-y {width: 100%;border-bottom: 1px dashed #607D8B;}.u-cursor-pt {position: absolute;top: 0;left: 0;border-radius: 50%;-webkit-filter: brightness(85%);filter: brightness(85%);pointer-events: none;will-change: transform;z-index: 100;}.u-select.u-off, .u-cursor-x.u-off, .u-cursor-y.u-off, .u-cursor-pt.u-off {display: none;}
.styles_anomalyCard__W4qx2 {
  min-height: 700px;
}
@media (max-width: 700px) {
  .styles_anomalyCard__W4qx2 {
    min-height: 400px;
  }
}

@media (max-width: 700px) {
  #anomalyChart {
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  .styles_expandBtn__1x_Bk {
    display: none;
  }
}
@media (min-width: 1360px) {
  .styles_calculatorInputForm__Kz8YW {
    min-width: 650px;
  }
}

.styles_expectedReturnInput__1y5vy .bp3-input-group input {
  padding-right: 10px !important;
}
.styles_bp3-tab-list__FsxJO {
  flex-wrap: wrap;
}

.styles_bp3-label__aqNjQ {
  min-width: 25%;
}

.styles_bp3-slider__2Nhy_ {
  min-width: 200px !important;
}

.styles_bp3-dark__2Zp4L svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__c6mH2 {
  display: none;
}

body {
  background-color: #f5f8fa;
}

.styles_darkBackgroundColor__3ff-H {
  background-color: #30404d;
}

.styles_contentPadding__1TKHb {
  margin-top: 5px;
  padding: 2vh;
}

@media only screen and (max-width: 768px) {
  .styles_contentPadding__1TKHb {
    margin-top: 5px;
    padding: 5px;
  }
}
.styles_lineChart__1_fYg {
  height: 400px;
}

.styles_dividerMargin__3gdkN {
  margin-bottom: 20px;
}

.styles_investmentsBar__2ytJS {
  padding-bottom: 2vh;
}
.styles_investmentsBar__2ytJS .bp3-card {
  padding-top: 13px;
  padding-bottom: 13px;
}

.styles_investmentFlowDetails__11Tni {
  padding-top: 2vh;
}

.styles_rowElementMinHeight__2DOpQ {
  min-height: 29rem;
}

@media (max-width: 700px) {
  .styles_portfoliSummaryCard__393bh {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .styles_calculatorInputForm__L7fdX {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media only screen and (max-width: 990px) {
  .styles_calculatorInputForm__L7fdX {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1600px) {
  .styles_calculatorLineChart__3DW_8 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media only screen and (max-width: 990px) {
  .styles_calculatorLineChart__3DW_8 {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .styles_investmentFlowDetailsCard__3O5P1 {
    overflow: auto;
  }
}
.styles_InvestmentFlowDetails__kxdh- {
  width: 100%;
}
.styles_InvestmentFlowDetails__kxdh- tbody tr td {
  padding: 3px 6px 3px 6px;
}
.styles_InvestmentFlowDetails__kxdh- tbody tr:last-child {
  font-weight: bold;
}
@media (max-width: 1400px) {
  .styles_InvestmentFlowDetails__kxdh- thead tr th {
    padding: 6px;
  }
}
.styles_PortfolioSummary__3i0Wh {
  padding-top: 20px;
  width: 100%;
  word-break: break-word;
  margin: 0 auto;
  width: 100%;
}
.styles_PortfolioSummary__3i0Wh thead tr th:nth-child(1) {
  width: 24%;
}
@media (max-width: 700px) {
  .styles_PortfolioSummary__3i0Wh {
    width: 100%;
    font-size: 12px !important;
  }
  .styles_PortfolioSummary__3i0Wh thead tr th {
    padding-left: 4px;
    padding-right: 1px;
  }
  .styles_PortfolioSummary__3i0Wh thead tr th:nth-child(1) {
    width: 28%;
  }
  .styles_PortfolioSummary__3i0Wh thead tr th:nth-child(2) {
    width: 26%;
  }
  .styles_PortfolioSummary__3i0Wh thead tr th:nth-child(3) {
    width: 26%;
  }
  .styles_PortfolioSummary__3i0Wh thead tr th:nth-child(4) {
    width: 20%;
  }
  .styles_PortfolioSummary__3i0Wh tbody tr td {
    padding-left: 5px;
    padding-right: 0;
  }
}

.bp3-input-group .bp3-input {
  width: auto !important;
}
.styles_bp3-tab-list__3Xsjf {
  flex-wrap: wrap;
}

.styles_bp3-label__2JPYz {
  min-width: 25%;
}

.styles_bp3-slider__9jiRS {
  min-width: 200px !important;
}

.styles_bp3-dark__1VXEE svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__E0Ry0 {
  display: none;
}

.styles_secondaryMenu__3_Atk {
  margin-top: 25px;
  box-shadow: none;
}
@media (max-width: 1920px) {
  .styles_secondaryMenu__3_Atk {
    padding: 15px;
  }
}
@media (max-width: 700px) {
  .styles_secondaryMenu__3_Atk {
    padding: 12px;
    width: 96%;
    margin-left: 2%;
  }
  .styles_secondaryMenu__3_Atk .bp3-button-group {
    flex-direction: column;
  }
  .styles_secondaryMenu__3_Atk .fundsSelector {
    align-self: center;
  }
  .styles_secondaryMenu__3_Atk .bp3-tab-list {
    justify-content: center;
  }
}

.styles_secondaryMenuButtonGroup__2VOKS {
  width: 100%;
  justify-content: space-between;
}
.ticker {
  width: 100%;
  height: 20px !important;
  align-self: center;
}

.ticker-component {
  display: flex;
}
.styles_indexCard__2n2Zl {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: 15px;
  max-width: 270px;
  margin-bottom: 15px;
}
.styles_postThumbImg__2yhnT {
  max-width: 300px;
  max-height: 150px;
  margin-right: 15px;
  cursor: pointer;
}
@media (max-width: 700px) {
  .styles_postThumbImg__2yhnT {
    place-self: center;
  }
}

.styles_postContent__35pHb {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
@media (max-width: 700px) {
  .styles_postContent__35pHb {
    flex-direction: column;
  }
}

.styles_postTitle__IfHxz {
  padding-bottom: 4px;
  cursor: pointer;
}

.styles_postPreviewCard__3jNSY {
  box-shadow: none;
}

@media (max-width: 700px) {
  .styles_postExcerpt__3RQTd {
    margin-top: 15px;
  }
}
.styles_previousBtn__UwScs {
  margin-right: 2px;
}

.styles_nextBtn__5O0iD {
  margin-left: 2px;
}

.styles_pageBtn__1bWUF {
  margin-left: 1px;
  margin-right: 1px;
}

.styles_paginationComponent__2fJ0p {
  width: 100%;
  margin-top: 15px;
  text-align-last: center;
}
.styles_postThumbImg__31Y5c {
  max-width: 90%;
  max-height: 90%;
  place-self: center;
}

.styles_postContent__2cyJG {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
@media (max-width: 700px) {
  .styles_postContent__2cyJG {
    flex-direction: column;
  }
}
.styles_postContent__2cyJG p {
  font-size: 15px;
}

.styles_postTitle__1ARS2 {
  padding-bottom: 4px;
}

.styles_postCard__1OQhz {
  box-shadow: none;
}

.styles_postHtml__21R0g {
  margin-top: 20px;
}

figcaption {
  text-align: center;
}

.styles_postColumn__3KGcg {
  width: 100%;
}

img {
  max-width: 100%;
}

.aligncenter {
  clear: both;
  display: block;
  float: none;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.styles_bp3-tab-list__3V93V {
  flex-wrap: wrap;
}

.styles_bp3-label__knlO4 {
  min-width: 25%;
}

.styles_bp3-slider__28dz3 {
  min-width: 200px !important;
}

.styles_bp3-dark__ZOh6U svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__tGSKt {
  display: none;
}

body {
  background-color: #f5f8fa;
}

.styles_darkBackgroundColor__FmgKz {
  background-color: #30404d;
}

.styles_topCard__1xQLB {
  margin-top: 2vh;
}

.styles_blogGridContainer__2uDmn {
  padding-left: 0px;
  padding-right: 0px;
}

.styles_panelDivider__1eH1m {
  margin-right: 20px !important;
}

.styles_bubu__36L30 {
  position: absolute;
  width: 90%;
  margin-top: 25px;
  background-color: white;
  margin-left: 5%;
}

.styles_blogGridCard__3-IJG {
  box-shadow: none;
}
@media (max-width: 1920px) {
  .styles_blogGridCard__3-IJG {
    overflow: auto;
  }
}

.styles_lastArticlesCallout__1eHIr {
  margin-top: 10px;
}

.styles_newsRow__38-OL {
  margin-top: 10px;
  text-align: left;
}
.styles_bp3-tab-list__1hvAS {
  flex-wrap: wrap;
}

.styles_bp3-label__2gFXN {
  min-width: 25%;
}

.styles_bp3-slider__1VZtt {
  min-width: 200px !important;
}

.styles_bp3-dark__10qVr svg g g text {
  fill: #f5f8fa !important;
}

.styles_hidden__1ZM0U {
  display: none;
}

body {
  background-color: #f5f8fa;
}

.styles_darkBackgroundColor__3oV3x {
  background-color: #30404d;
}
